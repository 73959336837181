import "./apartment-modal.scss"

import React from "react"

const ApartmentModal = ({ visualization3D, setModal }) => {
  return (
    <button className="apartment-modal" onClick={() => setModal(false)}>
      <div className="apartment-modal__wrapper">
        <img src={visualization3D} alt="" className="view__3D" />
      </div>
    </button>
  )
}

export default ApartmentModal
