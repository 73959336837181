import React from "react"
import {
  Page,
  Text,
  View,
  Image,
  Link,
  Font,
  Document,
  StyleSheet,
} from "@react-pdf/renderer"
import normal from "../assets/fonts/SourceSansPro/source-sans-pro-v13-latin-ext_latin-regular.ttf"
import light from "../assets/fonts/SourceSansPro/source-sans-pro-v13-latin-ext_latin-300.ttf"
import bold from "../assets/fonts/SourceSansPro/source-sans-pro-v13-latin-ext_latin-700.ttf"
import directions from "../assets/img/directions.png"
import logo from "../assets/img/logo.png"

Font.register({
  family: "Source Sans Pro",
  fonts: [
    { src: normal },
    { src: bold, fontWeight: "bold" },
    { src: light, fontWeight: 300 },
  ],
})

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  paragraph: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.7,
  },
  bold: {
    fontWeight: 700,
  },
  sup: {
    fontSize: 8,
    position: "relative",
    bottom: 10,
    left: 5,
  },
  uptitle: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
  },
  title: {
    fontFamily: "Source Sans Pro",
    fontSize: 32,
    fontWeight: 600,
    marginBottom: 20,
  },
  button: {
    fontFamily: "Source Sans Pro",
    padding: 10,
    backgroundColor: "#F8C1CE",
    borderRadius: 50,
  },
  apartmentInfo: {
    fontSize: 12,
    width: 102,
    marginRight: 300,
  },
  infoText: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.7,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 5,
    width: 300,
  },
  infoTextBig: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  infoTextBigwithMargin: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1,
    marginRight: 30,
  },
  infoTextSmall: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.7,
    marginLeft: 18,
    width: 10,
    position: "relative",
    top: 6,
  },
  infoTextSmall2: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.7,
    marginLeft: 18,
    width: 5,
    position: "relative",
    top: 6,
  },
  list: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    width: 300,
    posiiton: "absolute",
    bottom: 0,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 7,
    width: 300,
    borderBottomWidth: 1,
    borderBottomColor: "black",
    borderBottomStyle: "solid",
  },
  listItemText: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    fontSize: 12,
  },
  listItemTextContainer: {
    display: "flex",
    flexDirection: "row",
  },
  listItemTextSup: {
    position: "relative",
    bottom: 3,
    fontSize: 8,
    fontWeight: 300,
  },
  apartmentImages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: 300,
  },
  viewTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  viewTitleText: {
    fontFamily: "Source Sans Pro",
    fontWeight: 300,
    paddingRight: 20,
  },
  infoContainer: {
    fontFamily: "Source Sans Pro",
    position: "absolute",
    bottom: 0,
    fontWeight: 300,
    fontSize: 10,
    lineHeight: 1.7,
    marginTop: 100,
    width: 350,
  },
  imageVisualization: {
    height: 300,
  },
  imageVisualizationHolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 350,
  },
  imageVisualizationDirection: {
    width: 46,
    height: 54,
  },
  imageVisualization3d: {
    width: 400,
    height: "auto",
  },
  imageLocation: {
    marginTop: 20,
    width: 290,
    height: 83,
  },
  logo: {
    width: 154,
    height: "auto",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 5,
  },
  footerContact: {
    display: "flex",
    flexDirection: "column",
  },
  footerInfo: {
    fontFamily: "Source Sans Pro",
    fontSize: 10,
    position: "absolute",
    bottom: 25,
    left: 0,
    right: 0,
    margin: "0 auto",
    textAlign: "center",
  },
})

export function PdfView(props) {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.apartmentInfo}>
          <Text style={styles.uptitle}>Mieszkanie</Text>
          <Text style={styles.title}>{props.data.title}</Text>
          {/* <View style={styles.infoText}>
            <Text>Cena Brutto </Text>
            <View style={styles.infoTextBig}>
              <Text>{props.data.price}</Text>
              <Text style={styles.infoTextSmall}>PLN</Text>
            </View>
          </View> */}
          <View style={styles.infoText}>
            <Text>Metraż </Text>
            <View style={styles.infoTextBig}>
              <Text>{props.data.area}</Text>
              <Text
                style={{
                  position: "relative",
                  fontSize: 10,
                  left: 5,
                }}
              >
                {props.data.surfaceDivided && props.data.surfaceDivided}
              </Text>
              <Text style={styles.infoTextSmall2}>m</Text>
              <Text style={styles.sup}>2</Text>
            </View>
          </View>
          <View style={styles.infoText}>
            <Text>Ilość pokoi </Text>
            <Text style={styles.infoTextBigwithMargin}>
              {props.data.roomsNumber}
            </Text>
            <Text
              style={{
                right: 20,
                bottom: 14,
                position: "absolute",
                fontSize: 12,
              }}
            >
              {props.data.roomsDivided && props.data.roomsDivided}
            </Text>
          </View>
          <View style={styles.infoText}>
            <Text>Piętro</Text>
            <Text style={styles.infoTextBigwithMargin}>{props.data.floor}</Text>
          </View>
          <View style={styles.infoText}>
            <Text>Usytuowanie </Text>
            <Text style={styles.infoTextBigwithMargin}>
              {props.data.location}
            </Text>
          </View>
          <View style={styles.list}>
            {props.data.rooms && props.data.rooms.length > 0
              ? props.data.rooms.map((item, index) => (
                  <View style={styles.listItem} key={index}>
                    <Text style={styles.listItemText}>
                      {index + 1}.{item.name}
                    </Text>
                    <View style={styles.listItemTextContainer}>
                      <Text style={styles.listItemText}>{item.area} m</Text>
                      <Text style={styles.listItemTextSup}>2</Text>
                    </View>
                  </View>
                ))
              : null}
          </View>
        </View>
        <View style={styles.apartmentImages}>
          <View style={styles.imageVisualizationHolder}>
            <Image
              style={styles.imageVisualization}
              src={props.data.image2D}
              alt=""
            />
            <Image
              style={styles.imageVisualizationDirection}
              src={directions}
              alt=""
            />
          </View>
          <Image
            style={styles.imageLocation}
            src={props.data.imageLocation}
            alt=""
          />
          <View style={styles.infoContainer}>
            <View style={styles.footer}>
              <View style={styles.footerContact}>
                <Text style={styles.bold}>Biuro sprzedaży KW51</Text>
                <Text>ul. Kazimierza Wielkiego 51, Kraków</Text>
                <Text>
                  Klaudia tel. <Link src="tel:730637064">730 637 064</Link>
                </Text>
                <Text>
                  Wojciech tel. <Link src="tel:668492948">668 492 948</Link>
                </Text>
                <Text>
                  <Link href="https://kw51.pl/" traget="_blank">
                    www.kw51.pl
                  </Link>
                </Text>
              </View>
              <Image style={styles.logo} src={logo} alt="" />
            </View>
          </View>
        </View>
        <Text style={styles.footerInfo}>
          Powierzchnia całkowita obliczana wg. normy PN-ISO 9836 z 1997 z
          uwzględnieniem powierzchni pod ścianami działowymi
        </Text>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.apartmentInfo}>
          <Text style={styles.uptitle}>Mieszkanie</Text>
          <Text style={styles.title}>{props.data.title}</Text>
          {/* <View style={styles.infoText}>
            <Text>Cena Brutto </Text>
            <View style={styles.infoTextBig}>
              <Text>{props.data.price}</Text>
              <Text style={styles.infoTextSmall}>PLN</Text>
            </View>
          </View> */}
          <View style={styles.infoText}>
            <Text>Metraż </Text>
            <View style={styles.infoTextBig}>
              <Text>{props.data.area}</Text>
              <Text
                style={{
                  position: "relative",
                  fontSize: 10,
                  left: 5,
                }}
              >
                {props.data.surfaceDivided && props.data.surfaceDivided}
              </Text>
              <Text style={styles.infoTextSmall2}>m</Text>
              <Text style={styles.sup}>2</Text>
            </View>
          </View>
          <View style={styles.infoText}>
            <Text>Ilość pokoi </Text>
            <Text style={styles.infoTextBigwithMargin}>
              {props.data.roomsNumber}
            </Text>
            <Text
              style={{
                right: 20,
                bottom: 14,
                position: "absolute",
                fontSize: 12,
              }}
            >
              {props.data.roomsDivided && props.data.roomsDivided}
            </Text>
          </View>
          <View style={styles.infoText}>
            <Text>Piętro</Text>
            <Text style={styles.infoTextBigwithMargin}>{props.data.floor}</Text>
          </View>
          <View style={styles.infoText}>
            <Text>Usytuowanie </Text>
            <Text style={styles.infoTextBigwithMargin}>
              {props.data.location}
            </Text>
          </View>
          <View style={styles.list}>
            {props.data.rooms && props.data.rooms.length > 0
              ? props.data.rooms.map((item, index) => (
                  <View style={styles.listItem} key={index}>
                    <Text style={styles.listItemText}>
                      {index + 1}.{item.name}
                    </Text>
                    <View style={styles.listItemTextContainer}>
                      <Text style={styles.listItemText}>{item.area} m</Text>
                      <Text style={styles.listItemTextSup}>2</Text>
                    </View>
                  </View>
                ))
              : null}
          </View>
        </View>
        <View style={styles.apartmentImages}>
          <Image
            style={styles.imageVisualization3d}
            src={props.data.image3D}
            alt=""
          />
          <View style={styles.infoContainer}>
            <View style={styles.footer}>
              <View style={styles.footerContact}>
                <Text style={styles.bold}>Biuro sprzedaży KW51</Text>
                <Text>ul. Kazimierza Wielkiego 51, Kraków</Text>
                <Text>
                  Klaudia tel. <Link src="tel:730637064">730 637 064</Link>
                </Text>
                <Text>
                  Wojciech tel. <Link src="tel:668492948">668 492 948</Link>
                </Text>
                <Text>
                  <Link href="https://kw51.pl/" traget="_blank">
                    www.kw51.pl
                  </Link>
                </Text>
              </View>
              <Image style={styles.logo} src={logo} alt="" />
            </View>
          </View>
        </View>
        <Text style={styles.footerInfo}>
          Powierzchnia całkowita obliczana wg. normy PN-ISO 9836 z 1997 z
          uwzględnieniem powierzchni pod ścianami działowymi
        </Text>
      </Page>
    </Document>
    // <Document>
    //   <Page style={styles.page}>
    //     {props.data ? (
    //       <View>
    //         <View>
    //           <Text>Title: {props.data.title}</Text>
    //           <Text>Price: {props.data.price}</Text>
    //           <Text>Area: {props.data.area}</Text>
    //           <Text>Rooms number: {props.data.roomsNumber}</Text>
    //           <Text>Floor: {props.data.floor}</Text>
    //           <Text>Location: {props.data.location}</Text>
    //           <Text>Image 2D: {props.data.image2D}</Text>
    //           <Text>Image 3D: {props.data.image3D}</Text>
    //           <Text>Image location: {props.data.imageLocation}</Text>
    //         </View>
    //       </View>
    //     ) : (
    //       ""
    //     )}
    //   </Page>
    // </Document>
  )
}
