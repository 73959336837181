import React, { useState } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import { PdfView } from "../components/pdfView"
import ApartmentModal from "../components/ApartmentModal/ApartmentModal"

const Single = ({ pageContext }) => {
  const isBrowser = typeof window !== "undefined"
  const [viewItem, setViewItem] = useState(1)
  const [modal, setModal] = useState(false)

  const {
    title,
    area,
    floor,
    location,
    price,
    regular_price,
    discount_text,
    roomsNumber,
    visualization2D,
    visualization3D,
    floor_view,
    rooms_list,
    divided,
    status,
    surfaceDivided,
    roomsDivided,
    stage,
  } = pageContext.data

  const pdfData = {
    title: title,
    price: status !== "sold" ? price : "---",
    area: area,
    roomsNumber: roomsNumber,
    floor: floor,
    location: location,
    rooms: rooms_list,
    image2D: visualization2D ? visualization2D.localFile.publicURL : null,
    image3D: visualization3D ? visualization3D.localFile.publicURL : null,
    imageLocation: floor_view ? floor_view.localFile.publicURL : null,
    roomsDivided: roomsDivided,
    surfaceDivided: surfaceDivided,
  }

  const singleData = {
    modal,
    setModal,
    visualization3D: visualization3D.localFile.publicURL,
  }

  return (
    <Layout>
      <SEO title={`Mieszkanie ${title}`} />
      <Banner title={title} apartment={true} stage={stage} />

      <section className="single-apartment">
        <div className="container">
          <div className="row single-apartment__return-container">
            <div className="col-12 col-xxl-10 offset-xxl-1">
              <Link to="/mieszkania/" className="btn-return">
                Powrót
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-5 col-xxl-4 offset-xxl-1">
              <div
                className={`single-apartment__info ${
                  divided ? "single-apartment__info--small-margin" : ""
                }`}
              >
                <div className="info__item mb-10">
                  {regular_price !== null && (
                    <>
                      <p className="h3"></p>
                      <p className="item__number item__number--discount">
                        {regular_price.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                      </p>
                    </>
                  )}
                </div>
                {/* {status !== "sold" ? (
                  <div className="info__item mb-10">
                    <p className="h3">Cena brutto</p>
                    <p className="big-number item__number">
                      {price.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                      <span>PLN</span>
                    </p>
                  </div>
                ) : (
                  <div className="info__item"></div>
                )} */}
                <div className="info__item mb-20">
                  {regular_price !== null && (
                    <p
                      className="item__discount"
                      dangerouslySetInnerHTML={{ __html: discount_text }}
                    ></p>
                  )}
                </div>
                <div className="info__item mb-30">
                  <p className="h3">Metraż</p>
                  <p className="big-number item__number">
                    {area.replace(".", ",")}{" "}
                    <span className="divided-info divided-info--margin">
                      {surfaceDivided}
                    </span>
                    <span>
                      m<sup>2</sup>
                    </span>
                  </p>
                </div>
                <div className="info__item mb-30">
                  <p className="h3">Ilość pokoi</p>
                  <p className="big-number item__number">
                    {roomsNumber}{" "}
                    <span className="divided-info">{roomsDivided}</span>
                  </p>
                </div>
                <div className="info__item mb-30">
                  <p className="h3">Piętro</p>
                  <p className="big-number item__number">{floor}</p>
                </div>
                <div className="info__item">
                  <p className="h3">Usytuowanie</p>
                  <p className="big-number item__number">{location}</p>
                </div>
                {divided ? (
                  <div className="info__item info__item--padding">
                    <p className="h3">Możliwość podzielenia</p>
                    <p className="big-number item__number item__number--tick"></p>
                  </div>
                ) : null}
              </div>
              <div className="single-apartment__rooms-list">
                {rooms_list.map((item, index) => (
                  <div className="rooms-list__item" key={index}>
                    <p>
                      {index + 1}. {item.name}
                    </p>
                    <p>
                      {item.area.replace(".", ",")} m<sup>2</sup>
                    </p>
                  </div>
                ))}
              </div>
              <div className="single-apartment__button">
                {isBrowser && (
                  <PDFDownloadLink
                    document={<PdfView data={pdfData} />}
                    fileName={`mieszkanie ${title}.pdf`}
                    className="btn"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Ładowanie dokumentu..." : "Pobierz Pdf"
                    }
                  </PDFDownloadLink>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-7 col-xxl-6 offset-xxl-1 single-apartment__right-info">
              <div className="single-apartment__top-bar">
                <div
                  className={`single-apartment__view-nav ${
                    viewItem === 2 ? "single-apartment__view-nav--view3d" : ""
                  }`}
                >
                  <p className="view-nav__title">Widok</p>
                  <div className="view-nav__buttons">
                    <button
                      onClick={() => setViewItem(1)}
                      className={`nav-button ${
                        viewItem === 1 ? "nav-button--active" : ""
                      } `}
                    >
                      2D
                    </button>
                    <button
                      onClick={() => setViewItem(2)}
                      className={`nav-button ${
                        viewItem === 2 ? "nav-button--active" : ""
                      } `}
                    >
                      3D
                    </button>
                  </div>
                </div>
                {viewItem === 1 && (
                  <img
                    src={require("../assets/img/directions.svg")}
                    alt=""
                    className="top-bar__directions"
                  />
                )}
              </div>
              <div className="single-apartment__view">
                {viewItem === 1 && (
                  <div className="view__holder">
                    {visualization2D && (
                      <img
                        src={visualization2D.localFile.publicURL}
                        alt=""
                        className="holder__image"
                      />
                    )}
                  </div>
                )}
                {viewItem === 2 && (
                  <button
                    className="single-apartment__view-button"
                    onClick={() => setModal(true)}
                  >
                    {visualization3D && (
                      <img
                        src={visualization3D.localFile.publicURL}
                        alt=""
                        className="view__3D"
                      />
                    )}
                  </button>
                )}
              </div>
              <div className="single-apartment__floor-view">
                {floor_view && (
                  <img
                    src={floor_view.localFile.publicURL}
                    alt=""
                    className="floor-view__image"
                  />
                )}
                <p>
                  <span className="bold">Pamiętaj!</span> Mieszkania w naszej
                  inwestycji nie mają wewnętrznych ścian nośnych. Wizualizacja
                  jest naszą referencją, ale to Ty decydujesz ile pokoi będzie
                  miało Twoje mieszkanie.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {visualization3D && modal && <ApartmentModal {...singleData} />}
    </Layout>
  )
}

export default Single
